<template>
    <v-container fluid style="height: 100%">
		<v-row class="ma-0" v-if="!affichagePortable">
			<v-col cols="12" xs="12" class="pa-0">
				<v-row align="center" class="ma-0 my-4">
					<v-btn small fab outlined @click="openDialogueLegende = true" color="primary">
						<v-icon>mdi-help</v-icon>
					</v-btn>
					<div style="width: 180px;" class="ml-12 mr-12">
						<div class="primary--text text-caption">Dispos rendues:</div>
						<v-progress-linear rounded color="brown  lighten-3" height="25" :value="pourcentageDispos.valeur">
							<template v-slot="{ value }">
								<strong>{{ pourcentageDispos.texte }}</strong>
							</template>
						</v-progress-linear>
					</div>
					<div class="ml-12 mr-12 text-center">
						<div class="primary--text text-caption">Gardes à attribuer</div>
						<v-fade-transition mode="out-in">
							<div class="text--center font-weight-light text-h5" :key="total_besoins">{{total_besoins}}</div>
						</v-fade-transition>
					</div>
					
					<div class="ml-10 mr-10 text-center" style="width: 190px;">
						<div class="primary--text text-caption">Statut</div>
						<v-fade-transition mode="out-in">
							<v-chip :key="planning_courant ? planning_courant.id_statut : null" label text-color="white" :color="planning_courant && planning_courant.id_statut == 3 ? 'light-green darken-2' : 'amber darken-2'">
								<v-icon left color="white">{{planning_courant && planning_courant.id_statut == 3 ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'}}</v-icon>
								{{planning_courant && planning_courant.id_statut == 3 ? 'Planning diffusé' : 'Planning non diffusé'}}
							</v-chip>
						</v-fade-transition>
					</div>
					
					<div class="ml-10 mr-10 text-center" style="width: 220px;">
						<div class="primary--text text-caption">Date limite:</div>
						<v-fade-transition mode="out-in">
							<div class="text--center font-weight-light text-subtitle-1" :class="this.planning_courant && !this.planning_courant.date_limite_dispos ? 'font-italic' : ''" :key="planning_courant ? planning_courant.date_limite_dispos : null">{{date_limite}}</div>
						</v-fade-transition>
					</div>
					<v-spacer></v-spacer>
					<v-tooltip left>
						<template v-slot:activator="{ on }">
							<v-menu v-on="on" v-model="openChoixDateLimite"	:close-on-content-click="false" :close-on-click="false" nudge-bottom="10"
								:return-value.sync="date_limite_new" transition="scale-transition" offset-y	min-width="290px">
								<template v-slot:activator="{ on }">
									<v-btn small fab outlined @click="onClickChandeDateLimite" color="primary" v-on="on" :loading="chargement_date_limite" :disabled="(planning_courant && planning_courant.id_statut == 3) || !acces_autorise('creation planning gardes centre')">
										<v-icon>mdi-calendar-month</v-icon>
									</v-btn>
								</template>
								<v-row class="ma-0 pa-1" justify="center" style="background-color: #546E7A;"><div class="white--text font-weight-light">Choix de la date limite</div></v-row>
								<v-date-picker v-model="date_limite_new" scrollable :first-day-of-week="1">
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="onClickAnnuleDateLimite">Annuler</v-btn>
									<v-btn text color="primary" @click="changement_date_limite">OK</v-btn>
								</v-date-picker>
							</v-menu>
						</template>
						<span>Changer la date limite</span>
					</v-tooltip>
					<v-tooltip left>
						<template v-slot:activator="{ on }">
							<v-btn class="ml-3" small fab outlined @click="toggleVerrouillagePlanning" color="primary" v-on="on" :loading="chargement_statut" :disabled="(planning_courant && planning_courant.id_statut == 3) || !acces_autorise('creation planning gardes centre')">
								<v-icon v-if="planning_courant && planning_courant.id_statut == 2">mdi-lock-open-variant</v-icon>
								<v-icon v-else>mdi-lock</v-icon>
							</v-btn>
						</template>
						<span v-if="planning_courant && planning_courant.id_statut == 2">Déverrouiller le planning</span>
						<span v-else>Verrouiller le planning</span>
					</v-tooltip>
					<v-tooltip left>
						<template v-slot:activator="{ on }">
							<v-btn class="ml-3" small fab outlined @click="onClickDiffusionPlanning" color="primary" v-on="on" :loading="chargement_diffusion_planning" :disabled="(planning_courant && (planning_courant.id_statut == 3 || planning_courant.id_statut == 1)) || !acces_autorise('creation planning gardes centre')">
								<v-icon>mdi-cloud</v-icon>
							</v-btn>
						</template>
						<span>Diffuser le planning</span>
					</v-tooltip>
					<v-tooltip left>
						<template v-slot:activator="{ on }">
							<v-btn class="ml-3" small fab outlined @click="sauvePlanning" color="primary" v-on="on" :loading="chargement_sauv_planning" :disabled="!autorisation_sauvegarde_planning">
								<v-icon>mdi-content-save</v-icon>
							</v-btn>
						</template>
						<span>Sauvegarder le planning</span>
					</v-tooltip>
					
				</v-row>
				<v-row align="center" class="ma-0 mb-4 mt-12">
					<v-btn fab outlined small @click="mois_precedent" :loading="chargement_mois_precedent">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois">
							<v-fade-transition mode="out-in">
								<v-icon class="mr-4 mb-1" :key="planning_courant ? planning_courant.id_statut : null" v-if="planning_courant && planning_courant.id_statut == 2">mdi-lock</v-icon>
							</v-fade-transition>
							<span class="mx-12" style="cursor: pointer" @click="getPlanningMoisCourant">{{ titre_mois }}</span>
							<v-fade-transition mode="out-in">
								<v-icon class="ml-4 mb-1" :key="planning_courant ? planning_courant.id_statut : null" v-if="planning_courant && planning_courant.id_statut == 2">mdi-lock</v-icon>
							</v-fade-transition>
						</div>
					</v-slide-y-transition>
					<v-checkbox v-model="affichage_astreintes" class="mr-6 mt-0" hide-details color="primary" label="astreintes"></v-checkbox>
					<v-btn fab outlined small @click="mois_suivant" :loading="chargement_mois_suivant">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0 mt-6">
					<v-data-table id="table_planning" hide-default-footer 
						loading-text="Chargement du planning..." 
						no-data-text="Aucun planning"
						dense
						fixed-header
						disable-sort
						>
						<template v-slot:header="{ props: { headers } }">
							<thead>
								<tr>
									<th class="header_planning_nom">
										<v-row class="ma-0 pa-2" justify="center">
											<div class="text-center">Nom</div>
										</v-row>
									</th>
									<th class="header_planning_total_dispos">
										<v-row class="ma-0 pa-2" justify="center">
											<div class="text-center">Total dispos</div>
										</v-row>
									</th>
									<th class="header_planning_total_gardes">
										<v-row class="ma-0 pa-2" justify="center">
											<div class="text-center">Total gardes</div>
										</v-row>
									</th>
									<th class="header_planning_total_annuel_gardes">
										<v-row class="ma-0 pa-2" justify="center">
											<div class="text-center">Total annuel gardes</div>
										</v-row>
									</th>
									<th class="header_legende">
										<v-row class="ma-0 px-2 py-1" style="height: 100%">
											<v-col class="pa-0" align-self="end">
												<v-row class="ma-0" justify="end">
													besoin <v-icon size="10">mdi-arrow-right-thick</v-icon>
												</v-row>
												
												<v-row class="ma-0" justify="end">
													dispos <v-icon size="10">mdi-arrow-right-thick</v-icon>
												</v-row>
												<v-row class="ma-0" justify="end">
													attribué <v-icon size="10">mdi-arrow-right-thick</v-icon>
												</v-row>
											</v-col>
										</v-row>
									</th>
									<th v-for="header in headers_planning" :key="header.texte" class="px-0 header_planning_jour" :style="'min-width:'+creneaux.length*35+'px;max-width:'+creneaux.length*35+'px !important;'">
										<v-row class="ma-0 pa-2 header_jour_mois" justify="center" align="center">
											<div class="text-center" v-html="header.texte"></div>
										</v-row>
										<v-row class="ma-0 ligne_creneau">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'"  height="20" color="grey lighten-2" flat outlined tile>
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div class="font-weight-bold grey--text">{{premiereLettreMaj(creneau.nom)}}</div>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
										<v-row class="ma-0 ligne_besoin">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'"  height="20" :color="couleur_besoin_creneau(header.date_complete, creneau.id).couleur_fond" flat tile @click="onClickBesoin(header.date_complete, creneau.id)" @click.right.prevent="onClickRightBesoin(header.date_complete, creneau.id)">
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div class="font-weight-bold" :class="couleur_besoin_creneau(header.date_complete, creneau.id).couleur_texte">{{besoin_creneau(header.date_complete, creneau.id)}}</div>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
										<!-- somme des dispos du créneau -->
										<v-row class="ma-0 ligne_dispos_creneau">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'"  height="20" :color="couleur_besoin_creneau(header.date_complete, creneau.id).couleur_fond" flat tile>
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div class="font-weight-bold" :class="couleur_besoin_creneau(header.date_complete, creneau.id).couleur_texte">{{total_dispos_creneau(header.date_complete, creneau.id)}}</div>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
										<!-- somme des gardes attribuées du créneau -->
										<v-row class="ma-0 ligne_somme_gardes">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'"  height="20" :color="couleur_somme_garde_creneau(header.date_complete, creneau.id)" flat tile>
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div class="font-weight-bold grey--text">
															<v-fade-transition mode="out-in">
																<span :key="somme_gardes_creneau(header.date_complete, creneau.id)" class="white--text">{{somme_gardes_creneau(header.date_complete, creneau.id)}}</span>
															</v-fade-transition>
														</div>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
										
									</th>
								</tr>
							</thead>
							<thead>
								<tr>
									<th :colspan="33" class="ligne_progress_bar">
										<v-progress-linear v-show="chargement_planning"  color="deep-purple" :height="3" indeterminate></v-progress-linear>
									</th>
								</tr>
							</thead>
						</template>
						<template v-slot:body="{ items }">
							<tbody>
								<tr v-for="planning in liste_indiv" :key="planning.id" class="ligne_spv">
									<td class="colonne_nom">
										<v-row class="ma-0">
											{{ planning.membre.user.nom+' '+planning.membre.user.prenom }}
											<v-spacer></v-spacer>
											<v-tooltip v-if="planning.commentaire">
											</v-tooltip>
											<v-tooltip left v-if="planning.commentaire">
												<template v-slot:activator="{ on }">
													<v-icon size="20" color="primary" dark v-on="on">mdi-comment-text-outline</v-icon>
												</template>
												<span>{{planning.commentaire}}</span>
											</v-tooltip>
										</v-row>
									</td>
									<td class="colonne_total_dispos text-center pa-0">
										<v-row class="ma-0">
											<v-card width="100%" flat tile :color="total_dispos(planning) == 0 ? 'red lighten-3' :'transparent'" :class="total_dispos(planning) == 0 ? 'white--text' : ''" class="font-weight-bold">{{total_dispos(planning)}}</v-card>
										</v-row>
									</td>
									<td class="colonne_total_gardes text-center pa-0">
										<v-row class="ma-0">
											<v-card width="100%" flat tile :color="total_gardes(planning).couleur" class="white--text font-weight-bold">
												<v-fade-transition mode="out-in">
													<span :key="total_gardes(planning).total">{{total_gardes(planning).total}}</span>
												</v-fade-transition>
											</v-card>
										</v-row>
									</td>
									<td class="colonne_total_annuel_gardes text-center pa-0">
										<v-row class="ma-0">
											<v-card width="100%" flat tile color="indigo lighten-3" class="white--text font-weight-bold">
												<span>{{getTotalGardesAnnee(planning.id_membre)}}</span>
											</v-card>
										</v-row>
									</td>
									<td class="colonne_legende"></td>
									<td v-for="header in headers_planning" :key="header.date" class="px-0 colonne_jour" :class="estAstreinte(planning.membre, header.date_complete) ? 'jour_astreinte' : (estWeekend(header.date_complete) ? 'jour_weekend' : '')">
										<v-row class="ma-0">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'"  height="20" :color="couleur_card_jour(planning.id, header.date_complete, creneau.id).couleur" flat tile style="cursor: pointer; z-index: 0 !important" @click="onClickCreneau(planning.id, header.date_complete, creneau.id)">
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div class="font-weight-bold white--text">{{couleur_card_jour(planning.id, header.date_complete, creneau.id).texte}}</div>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
									</td>
								</tr>
							</tbody>
      					</template>

					</v-data-table>
				</v-row>
			</v-col>
		</v-row>
		<v-row v-else class="mx-12" justify="center" style="height: 100%" align-content="center">
			<div class="mb-12">
				<div class="text-center"><v-icon x-large>mdi-emoticon-sad-outline</v-icon></div>
            	<div class="text-center font-italic mt-2">Désolé, cette page n'est pas disponible sur cette taille d'écran.</div>
			</div>
		</v-row>

		<!-- dialogue legende -->
        <v-dialog v-model="openDialogueLegende" max-width="450" @click:outside="openDialogueLegende = false">
            <v-card>
				<v-card-title class="text-h6">Légende</v-card-title>
                <v-card-text>
					<v-row class="ma-0 mb-2">
						Lignes "besoins" et "dispos":
					</v-row>
					<v-row class="ma-0">
						<v-chip small class="mr-2 chip_legende" label color="red lighten-3"></v-chip>
						les dispos sont inférieures au besoin
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-chip small class="mr-2 chip_legende" label color="orange lighten-3"></v-chip>
						les dispos sont juste égales au besoin
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-chip small class="mr-2 chip_legende" label color="light-green lighten-3"></v-chip>
						les dispos sont supérieures au besoin
					</v-row>

					<v-row class="ma-0 mb-2 mt-6">
						Lignes "attribué":
					</v-row>
					<v-row class="ma-0">
						<v-chip small class="mr-2 chip_legende" label color="red lighten-3"></v-chip>
						les gardes attribuées sont inférieures au besoin
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-chip small class="mr-2 chip_legende" label color="orange lighten-3"></v-chip>
						les gardes attribuées sont supérieures au besoin
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-chip small class="mr-2 chip_legende" label color="light-green lighten-3"></v-chip>
						les gardes attribuées sont égales au besoin
					</v-row>
                </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue-grey darken-1" text @click="openDialogueLegende = false">Fermer</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>	

		<!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueDiffusion" max-width="290" persistent>
            <v-card>
				<v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Diffusion</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ message_confirmation }}</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="openDialogueDiffusion = false">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="diffusionPlanning" :loading="chargement_diffusion_planning">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import Moment from 'moment'
	Moment.locale('fr')
	import { extendMoment } from 'moment-range'
	const moment = extendMoment(Moment)
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Plannification des gardes')
        },
        mounted() {
			this.mois_courant = moment().startOf('month').format('YYYY-MM-DD')
			this.chargement_mois_precedent = true
			this.chargement_mois_suivant = true
			this.fetchPlanningCentre()
        },
        data() {
			return {
				affichage_astreintes: false,
				chargement_date_limite: false,
				chargement_diffusion_planning: false,
				chargement_mois_precedent: false,
				chargement_mois_suivant: false,
				chargement_planning: false,
				chargement_sauv_planning: false,
				chargement_statut: false,
				date_limite_new: null,
				message_confirmation: '',
				mois_courant: null,
				openDialogueDiffusion: false,
				openDialogueLegende: false,
				openChoixDateLimite: false,
				planning_courant: null,
				liste_indiv: [],
				totaux_gardes: []
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			autorisation_sauvegarde_planning() {
				//	on autorise à sauvegarder si:
				//		-planning non diffusé et permission 'creation planning gardes centre'
				//	ou	-planning diffusé et permission 'modifications planning gardes centre'

				if(this.planning_courant && (this.planning_courant.id_statut == 2) && this.acces_autorise('creation planning gardes centre')) {
					return true
				}
				if(this.planning_courant && (this.planning_courant.id_statut == 3) && this.acces_autorise('modifications planning gardes centre')) {
					return true
				}
				
				return false
			},		
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			creneaux() {
				return this.$store.getters.membreCourant.centre.periodes
			},
			date_limite() {
				if(!this.planning_courant) return ''
				if(!this.planning_courant.date_limite_dispos) return 'non définie'
				return moment(this.planning_courant.date_limite_dispos).format('dddd DD/MM/YYYY')+' à '+moment(this.planning_courant.date_limite_dispos).format('HH:mm')
			},
			headers_planning() {
				let tableau = []
				const range = moment.range(moment(this.mois_courant), moment(this.mois_courant).endOf('month'))
				for (let jour of range.by('day')) {
					//tableau.push(jour.format('dddd D'))
					tableau.push(
						{
							texte: jour.format('dddd')+'<br>'+jour.format('D'),
							date_complete: jour.format('YYYY-MM-DD')
						}
					)	
				}
				return tableau
			},
			pourcentageDispos() {
				if(!this.planning_courant) {
					return {
						valeur: 0,
						texte: 'aucun planning'
					}
				}
				
				const nbre_spv = this.planning_courant ? this.planning_courant.plannifications.length : 0
				let nbre_spv_avec_dispos = 0
				this.planning_courant.plannifications.forEach(planif => {
					if(this.total_dispos(planif) > 0) nbre_spv_avec_dispos += 1
				}) 
				return {
					valeur: nbre_spv > 0 ? nbre_spv_avec_dispos / nbre_spv * 100 : 0,
					texte: nbre_spv > 0 ? nbre_spv_avec_dispos+'/'+nbre_spv : 'aucune dispo'
				}
			},
			titre_mois() {
				if(!this.mois_courant) return ''
				return moment(this.mois_courant).format('MMMM YYYY')
			},
			total_besoins() {
				if(!this.planning_courant) return 0
				let somme =0
				this.planning_courant.besoins.forEach(besoin => {
					somme = somme + besoin.quantite
				})
				return somme
			}
  
		},
		methods: {
			acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			
			besoin_creneau(date, id_creneau) {
				if(!this.planning_courant) return 0
				const besoin = this.planning_courant.besoins.find( besoin => besoin.date == date && besoin.id_periode == id_creneau)
				if(besoin) {
					return besoin.quantite
				}
				// si pas trouvé on retourne 0
				return 0
			},
			changement_date_limite() {
				this.openChoixDateLimite = false
				this.chargement_date_limite = true
				axios.post('api/planning/date_limite', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, id_planning: this.planning_courant ? this.planning_courant.id : null, date: moment(this.date_limite_new).format('YYYY-MM-DD 23:59') })
                    .then( (reponse) => {
						this.snackbar('success',reponse.data.resultat)
                    })
                    .catch( (error) => {
						if(error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors du changement de la date limite')
						}
                       
					})
                    .then(() => {
						this.chargement_date_limite = false
						this.fetchPlanningCentre()
                    })
			},
			couleur_besoin_creneau(date, id_creneau) {
				//	change la couleur du besoin si le nbre de dipos est insuffisant
				let couleur_fond = 'grey lighten-3'
				let couleur_texte = 'grey--text'

				//	si nbre besoin > nbre dispos on met en rouge
				if(this.besoin_creneau(date, id_creneau) > this.total_dispos_creneau(date, id_creneau)) {
					couleur_fond = 'red lighten-3'
					couleur_texte = 'white--text'
				}

				//	si juste besoin on met orange
				else if(this.besoin_creneau(date, id_creneau) > 0 && this.besoin_creneau(date, id_creneau) == this.total_dispos_creneau(date, id_creneau)) {
					couleur_fond = 'orange lighten-3'
					couleur_texte = 'white--text'
				}

				return {
					couleur_fond: couleur_fond,
					couleur_texte: couleur_texte
				}
			},
			couleur_card_jour(id_liste_indiv, date, id_creneau) {
				//	retourne la couleur de fond à afficher
				let texte = ''
				let couleur = 'transparent'

				const planning_indiv = this.liste_indiv.find( planning => planning.id == id_liste_indiv)
				if(!planning_indiv) return {texte: '', couleur: 'transparent'}

				const creneau_indiv = planning_indiv.gardes.find( jour => jour.date == date && jour.id_periode == id_creneau)
				if(!creneau_indiv) return {texte: '', couleur: 'transparent'}
				
				if(creneau_indiv.dispo) {
					couleur = 'deep-purple lighten-4'
				}
				if(creneau_indiv.garde) {
					couleur = 'cyan lighten-3'
					texte = 'G'
				}
				return {texte: texte, couleur: couleur}

			},
			couleur_somme_garde_creneau(date, id_creneau) {
				//	détermine la couleur de fond en fonction du nombre de garde attribuées et du besoin
				if(this.somme_gardes_creneau(date, id_creneau) == this.besoin_creneau(date, id_creneau)) {
					return 'light-green lighten-3'
				}
				else if(this.somme_gardes_creneau(date, id_creneau) > this.besoin_creneau(date, id_creneau)) {
					return 'orange lighten-3'
				}
				else {
					return 'red lighten-3'
				}
			},
			diffusionPlanning() {
				this.chargement_diffusion_planning = true
				this.sauvePlanning(false).then(() => {
					axios.post('api/planning/diffusion', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, id_planning: this.planning_courant ? this.planning_courant.id : null })
                    .then( (reponse) => {
						this.snackbar('success',reponse.data.resultat)
                    })
                    .catch( (error) => {
						if(error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors de la diffusion du planning')
						}
                       
					})
                    .then(() => {
						this.chargement_diffusion_planning = false
						this.fetchPlanningCentre()
						this.openDialogueDiffusion = false
						
                    })
				})
				
			},
			estAstreinte(membre, date) {
				//	retourne true si le membre est d'astreinte à la date donnée
				
				if(!membre?.equipe?.astreintes) return false 
				
				if(!this.affichage_astreintes) return false

				let astreinte = false
				membre.equipe.astreintes.forEach(plage => {
					if(moment(date).isBetween(plage.date_debut, moment(plage.date_fin).subtract(1, 'days'), 'day', '[]')) astreinte = true
				})
				return astreinte
			},
			estWeekend(date) {
				return moment(date).day() == 6 || moment(date).day() == 0
			},
			fetchPlanningCentre() {
				// récupère les données du planning du mois donné (mois en cours par défaut)
				this.chargement_planning = true
				this.liste_indiv = []
				axios.post('api/planning/planning_centre', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, date_debut: this.mois_courant, date_fin: moment(this.mois_courant).endOf('month').format('YYYY-MM-DD') })
                    .then( (reponse) => {
						this.planning_courant = reponse.data.planning
						this.totaux_gardes = reponse.data.comptage_gardes
						
						//	 on réorganise les plannings individuels par ordre alphabétique de l'utilisateur
						this.liste_indiv = reponse.data.planning && reponse.data.planning.plannifications ? reponse.data.planning.plannifications.sort((a,b) => {
							if(a.membre.user.nom.toLowerCase() < b.membre.user.nom.toLowerCase()) {
								return -1
							}
							else if(a.membre.user.nom.toLowerCase() > b.membre.user.nom.toLowerCase()) {
								return 1
							}

							//	ici on a le même nom donc on tri par prénom
							if(a.membre.user.prenom.toLowerCase() < b.membre.user.prenom.toLowerCase()) {
								return -1
							}
							else if(a.membre.user.prenom.toLowerCase() > b.membre.user.prenom.toLowerCase()) {
								return 1
							}

							return 0
							}) : []
				
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement du planning');
                    })
                    .then(() => {
						this.chargement_planning = false
						this.chargement_mois_precedent = false
						this.chargement_mois_suivant = false
                    })
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			getPlanningMoisCourant() {
				this.mois_courant = moment().startOf('month').format('YYYY-MM-DD')
				this.chargement_mois_precedent = true
				this.chargement_mois_suivant = true
				this.fetchPlanningCentre()
			},
			getTotalGardesAnnee(id_membre) {
				const total = this.totaux_gardes.find( nbre => nbre.id_membre == id_membre)
				if (total) return total.total_gardes
				return 0
			},
			mois_precedent() {
				this.mois_courant = moment(this.mois_courant).subtract(1, 'month').format('YYYY-MM-DD')
				this.chargement_mois_precedent= true
				this.fetchPlanningCentre()
			},
			mois_suivant() {
				this.mois_courant = moment(this.mois_courant).add(1, 'month').format('YYYY-MM-DD')
				this.chargement_mois_suivant = true
				this.fetchPlanningCentre()
			},
			onClickAnnuleDateLimite() {
				this.date_limite_new = null
				this.openChoixDateLimite = false
			},
			onClickBesoin(date, id_creneau) {
				if(this.planning_courant) {
					const index = this.planning_courant.besoins.findIndex( besoin => besoin.date == date && besoin.id_periode == id_creneau)
					
					//	si existant on incremente
					if(index > -1) {
						this.planning_courant.besoins[index].quantite += 1
					}

					//	s'il n'existe pas on l'ajoute et on met sa valeur à 1
					else {
						this.planning_courant.besoins.push({
							id: null,
							id_planning: this.planning_courant.id,
							id_periode: id_creneau,
							date: date,
							quantite: 1
						})
					}
				}
			},
			onClickChandeDateLimite() {
				//this.date_limite_new = moment(this.planning_courant.date_limite_dispos).format('YYYY-MM-DD')
				if(this.planning_courant.date_limite_dispos) {
					this.date_limite_new = moment(this.planning_courant.date_limite_dispos).format('YYYY-MM-DD')
				}
				else {
					this.date_limite_new = moment().format('YYYY-MM-DD')
				}
				this.openChoixDateLimite = true
			},
			onClickDiffusionPlanning() {
				const particule = [3,7,9].includes(moment(this.mois_courant).get('month')) ? 'd\'' : 'de '
				this.message_confirmation = 'Diffuser le planning '+particule+moment(this.mois_courant).format('MMMM YYYY')+'?'
				this.openDialogueDiffusion = true
			},
			onClickRightBesoin(date, id_creneau) {
				// diminue le nbre de besoin du créneau
				if(this.planning_courant) {
					const index = this.planning_courant.besoins.findIndex( besoin => besoin.date == date && besoin.id_periode == id_creneau)
					
					//	si existant on incremente
					if(index > -1 && this.planning_courant.besoins[index].quantite > 0) {
						this.planning_courant.besoins[index].quantite -= 1
					}

				}
			},
			onClickCreneau(id_liste_indiv, date, id_creneau) {
				// on ne fait rien si le planning n'est pas verrouillé ou n'a pas été déjà diffusé
				if(this.planning_courant.id_statut == 1) {
					this.snackbar('info', 'Le planning doit être verrouillé avant d\'attribuer des gardes')
					return
				}
				
				const planning_indiv = this.liste_indiv.find( planning => planning.id == id_liste_indiv)
				if(planning_indiv) {
					const creneau_indiv = planning_indiv.gardes.find( jour => jour.date == date && jour.id_periode == id_creneau)
					
					if(creneau_indiv) {
						creneau_indiv.garde = !creneau_indiv.garde
					}
					//	si pas de creneau trouvé c'est que le sPV n'est pas dispo, on crée donc le creneau
					else {
						planning_indiv.gardes.push({
							date: date,
							dispo: false,
							garde: true,
							id: null,
							id_periode: id_creneau,
							id_plannification: planning_indiv.id
						})
					}
				}
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			sauvePlanning(rechargement_apres = true) {
				return new Promise ((resolve, reject) => {
					this.chargement_sauv_planning = true
					axios.post('api/planning/sauvegarde', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, id_planning: this.planning_courant ? this.planning_courant.id : null, liste: this.liste_indiv })
						.then( (reponse) => {
							this.snackbar('success',reponse.data.resultat)
							resolve(reponse);
						})
						.catch( (error) => {
							if(error.response.data.error) {
								this.snackbar('error', error.response.data.error)
							}
							else {
								this.snackbar('error', 'Erreur lors de la sauvegarde du planning')
							}
							reject(error)
						
						})
						.then(() => {
							this.chargement_sauv_planning = false
							// si on doit diffuser après on ne recharge pas
							if(rechargement_apres) this.fetchPlanningCentre()						
						})
				})
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			somme_gardes_creneau(date, id_creneau) {
				let somme = 0
				if(this.planning_courant && this.planning_courant.plannifications) {
					this.planning_courant.plannifications.forEach(planning_indiv => {
						const creneau = planning_indiv.gardes.find( jour => jour.date == date && jour.id_periode == id_creneau)
						if(creneau && creneau.garde) somme += 1
					})
				}
				return somme
			},
			toggleVerrouillagePlanning() {
				this.chargement_statut = true
				axios.post('api/planning/verrouillage', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, id_planning: this.planning_courant ? this.planning_courant.id : null })
                    .then( (reponse) => {
						this.snackbar('success',reponse.data.resultat)
                    })
                    .catch( (error) => {
                       this.snackbar('error', 'Erreur lors du changement de verrouillage du planning')
					})
                    .then(() => {
						this.chargement_statut = false
						this.fetchPlanningCentre()
						
                    })
			},
			total_dispos(planning) {
				//	retourne le nombre total de dispos du membre
				let somme = 0
				planning.gardes.forEach(jour => {
					if(jour.dispo) somme += 1
				})
				return somme
			},
			total_dispos_creneau(date, id_creneau) {
				//	retourne le nombre de sipos selon le creneau
				let somme = 0
				if(this.planning_courant && this.planning_courant.plannifications) {
					this.planning_courant.plannifications.forEach(planning_indiv => {
						const creneau = planning_indiv.gardes.find( jour => jour.date == date && jour.id_periode == id_creneau)
						if(creneau && creneau.dispo) somme += 1
					})
				}
				return somme
			},
			total_gardes(planning) {
				//	retourne le nombre total de gardes du membre et la couleur de l'arrier-plan selon la valeur
				let somme = 0
				let couleur = 'grey lighten-5'
				planning.gardes.forEach(jour => {
					if(jour.garde) somme += 1
				})

				if(somme == 0) {
					couleur = 'red lighten-3'
				}
				if(somme > 0 && somme < 3){
					couleur = 'orange lighten-3'
				}
				if(somme >= 3) {
					couleur = 'light-green lighten-3'
				}

				return {
					total: somme,
					couleur: couleur
				}
			}

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces plannification gardes')) {
					this.$router.push('/')
				}
				else {
					this.fetchPlanningCentre()
				}
			}
		}
  	}
</script>

<style scoped>
.chip_legende {
	width: 50px;
}

.colonne_jour {
	z-index: 0;
	border-right: 1px solid #CFD8DC;
}


.colonne_legende {
	background-color: #E0E0E0 !important;
	border-bottom: 1px solid #E0E0E0 !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky;
	left: 480px;
	z-index: 1;
	padding: 0 !important;
}


.colonne_nom {
	background-color: #FAFAFA;
	position: -webkit-sticky; /* Safari */
  	position: sticky;
	left: 0;
	padding-bottom: 0 !important;
	padding-top: 0 !important;
	z-index: 1;
	cursor: pointer;
}

/* .colonne_nom:hover {
	background-color: red;
} */

.colonne_total_dispos {
	background-color: #FAFAFA;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 300px;
	z-index: 1;
}

.colonne_total_annuel_gardes {
	background-color: #FAFAFA;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 420px;
	z-index: 1;
}

.colonne_total_gardes {
	background-color: #FAFAFA;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 360px;
	z-index: 1;
}

.header_jour_mois {
	min-height: 50px !important;
	max-height: 50px !important;
}

.header_legende{
	padding: 0 !important;
	background-color: #E0E0E0 !important;
	min-width: 70px;
	max-width: 70px !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky;
	left: 480px;
	z-index: 6 !important;
}

.header_planning_jour {
	/* min-width: 70px;
	max-width: 70px !important; */
	border-top: 1px solid #CFD8DC;
	border-right: 1px solid #CFD8DC;
	background-color: #E0E0E0 !important;
	z-index: 5 !important;
}

.header_planning_nom {
	min-width: 300px;
	max-width: 300px !important;
	border-top: 1px solid #CFD8DC;
	border-right: 1px solid #CFD8DC;
	border-left: 1px solid #CFD8DC;
	background-color: #E0E0E0 !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky;
	left: 0;
	z-index: 6 !important;
}

.header_planning_total_dispos {
	min-width: 60px;
	max-width: 60px !important;
	border-top: 1px solid #CFD8DC;
	border-right: 1px solid #CFD8DC;
	background-color: #E0E0E0 !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 300px;
	z-index: 6 !important;
}

.header_planning_total_annuel_gardes {
	min-width: 60px;
	max-width: 60px !important;
	border-top: 1px solid #CFD8DC;
	border-right: 1px solid #CFD8DC;
	background-color: #E0E0E0 !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 420px;
	z-index: 6 !important;
}

.header_planning_total_gardes {
	min-width: 60px;
	max-width: 60px !important;
	border-top: 1px solid #CFD8DC;
	border-right: 1px solid #CFD8DC;
	background-color: #E0E0E0 !important;
	position: -webkit-sticky; /* Safari */
  	position: sticky !important;
	left: 360px;
	z-index: 6 !important;
}

.jour_astreinte {
	background-color: rgba(238, 89, 89, 0.144) !important;
}

.jour_weekend {
	background-color: #F5F5F5 !important;
}

.ligne_besoin, .ligne_somme_gardes, .ligne_dispos_creneau, .ligne_creneau {
	border-top: 1px solid #CFD8DC;
}

.ligne_progress_bar {
	padding: 0 !important;
	height: 3px !important;
	z-index: -1 !important;
}




#table_planning {
	max-width: 100%;
	overflow-y: auto;
}

#table_planning .v-data-table__wrapper table tbody td {
	height: 20px;
}



</style>

<style>
#table_planning .v-data-table__wrapper {
    max-height: calc(100vh - 295px);
    overflow-y: auto;
}

.ligne_spv:hover td.colonne_nom {
	background-color: #E0E0E0 !important;
}


</style>